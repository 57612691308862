document.addEventListener('DOMContentLoaded', () => {
  document.querySelectorAll('[data-id="unit-deposit-calculator"]').forEach(form => {
    form.addEventListener('submit', e => {
      e.preventDefault()

      const productiveForestAreaInput = form.querySelector('input[name="productive_forest_area"]')
      const productiveForestAreaValue = Number(productiveForestAreaInput.value)

      if (typeof productiveForestAreaValue !== 'number' || isNaN(productiveForestAreaValue)) return

      if (productiveForestAreaValue < 100) {
        form.querySelector('input[name="share_deposit"]').value = numberWithSpaces(3000)
        return
      }

      // Calculate price
      const price = Math.round(Math.pow(productiveForestAreaValue / 20, 0.7) * 1000)
      // round to closest 500
      const rounded = Math.round(price / 500) * 500
      // Update price
      form.querySelector('input[name="share_deposit"]').value = numberWithSpaces(rounded)
    })
  })
})

// Makes a price(number) pretty(string)
function numberWithSpaces (x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
}
